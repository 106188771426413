<template>
  <div id="backdrop">
  <div class="text-center loading">
      <div class="spinner-border" role="status">
          <span class="sr-only"></span>
      </div>
  </div>
</div>
</template>
<style scoped>
.text-center {
    width: 100%;
    position: relative;
    height: 100%;
}

.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));  
    right: calc(50% - (58px / 2));
    color: rgba(8, 0, 255, 0.427);
}

#backdrop {
  position:absolute;
  top:0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.164);
}
</style>