<template>
  <loadingBackDrop v-if="loadingTx" />
  <Layout>
    <PageHeader :title="title" :breadcrumbs="breadcrumbs" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">
                    Time for a drink !!
                  </h4>
                </div>
                <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div class="row g-3 mb-0 align-items-center">
                      <!--end col-->
                          <div class="col-auto">
                        <button
                          @click="enableDisableFreeAirdrop()"
                          type="button"
                          v-if="isFreeNftEnabled"
                          class="btn btn-success"
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Free NFT is Enabled
                        </button>

                        <button
                          @click="enableDisableFreeAirdrop()"
                          type="button"
                          class="btn btn-danger"
                          v-else
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Free NFT Disabled
                        </button>
                      </div>

                      <div class="col-auto">
                        <button
                          @click="openAndClose()"
                          type="button"
                          v-if="isOpen"
                          class="btn btn-success"
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Contract is Open
                        </button>

                        <button
                          @click="openAndClose()"
                          type="button"
                          class="btn btn-danger"
                          v-else
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Contract is Closed
                        </button>
                      </div>

                      <div class="col-auto">
                        <button
                          type="button"
                          v-if="whitelisted"
                          class="btn btn-danger"
                          @click="setWhiteListed()"
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Whitelisted
                        </button>

                        <button
                          @click="setWhiteListed()"
                          type="button"
                          class="btn btn-success"
                          v-else
                        >
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Public Mint
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        Name
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{ preview.name }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        Symbol
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{ preview.symbol }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        Contract Balance
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-16 fw-semibold ff-secondary mb-4">
                        {{ balance }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate" @click="openPayoutAddressModal()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        Payout Address
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{
                          preview &&
                          preview.payoutAddress &&
                          preview.payoutAddress.substring(0, 6)
                        }}
                        ...
                        {{
                          preview &&
                          preview.payoutAddress &&
                          preview.payoutAddress.substring(
                            preview.payoutAddress.length - 4,
                            preview.payoutAddress.length
                          )
                        }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
            </div>

            <div class="col-xl-3 col-md-6">
              <div class="card card-animate" @click="openMintMaxModal()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        Mint Max
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{ preview.mintMax }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate" @click="openHoldMax()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        Hold Max
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-16 fw-semibold ff-secondary mb-4">
                        {{ preview.holdMax }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate" @click="openSetStageModal()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Stage
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{ preview.stage }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-success rounded fs-3">
                        <i class="bx bx-dollar-circle text-success"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate" @click="openMaxSupplyModal()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Total Minted/ Max Supply
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{ preview.totalMinted }}/{{ preview.maxSupply }}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-warning rounded fs-3">
                        <i class="bx bx-user-circle text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>

            <div class="col-xl-6 col-md-6">
              <!-- card -->
              <div class="card card-animate" @click="setPrice()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium mb-0">Price</p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{ preview.usdPrice }} USD /
                        {{ preview.mintPrice / 10 ** 18 }} ETH
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-light rounded fs-3">
                        <i class="bx bx-shopping-bag text-white"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div
                class="card card-animate bg-danger"
                @click="openAirDropModal()"
              >
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-white-50 mb-0">
                        Airdrop
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-light rounded fs-3">
                        <i class="bx bx-shopping-bag text-white"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate" @click="openWithdraw()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Withdraw
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <div class="col-xl-6 col-md-6">
              <!-- card -->
              <div class="card card-animate" @click="setUrl()">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="text-uppercase fw-medium text-muted text-truncate mb-0"
                      >
                        Base URL
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h2 class="fs-12 fw-semibold ff-secondary mb-4">
                        <a href="" class="">{{
                          preview.baseURI ? preview.baseURI : " Not set yet"
                        }}</a>
                      </h2>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Withdraw Modal-->
    <baseModal
      title="Set Max Supply"
      labelButton="Sign Transaction"
      :show="maxSupplyShow"
      @close="[maxSupplyShow = false, closeModal()]"
      @call="setMaxSupply()"
    >
      <div class="search-box">
        <input
          type="number"
          v-model="maxSupply"
          class="form-control"
          min="{{preview.totalMinted()}}"
          placeholder="Max Supply"
          required
        />
      </div>
    </baseModal>
    <!--End Withdraw Modal-->
    <!--Withdraw Modal-->
    <baseModal
      title="Withdraw ETH"
      labelButton="Withdraw"
      :show="withdrawShow"
      @close="[withdrawShow = false,closeModal()]"
      @call="withdraw()"
    >
      <div class="search-box pb-3">
        <input
          type="text"
          v-model="preview.payoutAddress"
          class="form-control"
          disabled
        />
      </div>
      <div class="search-box">
        <input
          type="number"
          v-model="withdrawToken"
          class="form-control"
          placeholder="Amount"
          required
        />
      </div>
    </baseModal>
    <!--End Withdraw Modal-->

    <!--Mint Max Modal-->
    <baseModal
      title="Set Mint Max"
      labelButton="Sign Transaction"
      :show="mintMaxShow"
      @close="[mintMaxShow = false, closeModal()]"
      @call="setMintMax()"
    >
      <div class="search-box">
        <input
          type="number"
          v-model="mintMaxAmount"
          class="form-control"
          placeholder="Amount"
          required
        />
      </div>
    </baseModal>
    <!--End Mint Max Modal-->

    <!--Payout Modal-->
    <baseModal
      title="Set Payout Address"
      labelButton="Sign Transaction"
      :show="payoutAddressShow"
      @close="[payoutAddressShow = false, closeModal()]"
      @call="setPayoutAddress()"
    >
      <div class="search-box">
        <input
          type="string"
          v-model="payoutAddress"
          class="form-control"
          placeholder="Address"
          required
        />
      </div>
    </baseModal>
    <!--End Payout Modal-->

    <!--AirDrop Modal-->
    <baseModal
      title="AirDrop"
      labelButton="Send NFTS"
      :show="airDropShow"
      @close="[airDropShow = false, closeModal()]"
      @call="airDrop()"
    >
      <div class="search-box pb-3">
        <input
          type="text"
          v-model="airdropAd"
          class="form-control"
          placeholder="Address"
          required
        />
      </div>
      <div class="search-box">
        <input
          type="number"
          v-model="airDropToken"
          min="1"
          class="form-control"
          placeholder="Amount"
          required
        />
      </div>
    </baseModal>
    <!--End AirDrop Modal-->
    <!--Set PriceModal-->
    <baseModal
      title="Set Price"
      labelButton="Sign Transaction"
      :show="priceShow"
      @close="[priceShow = false, closeModal()]"
      @call="callSetPrice()"
    >
      <div class="search-box pb-3">
        <input
          type="number"
          min="1"
          v-model="priceUSD"
          class="form-control"
          placeholder="Price(USD)"
          required
        />
      </div>
      <label>{{ calculatePrice() }} ETH</label>
    </baseModal>
    <!--End Set PriceModal-->
    <!--Set URL Modal-->
    <baseModal
      title="Base URI"
      labelButton="SET BASE URI"
      :show="urlShow"
      @close="[urlShow = false, closeModal()]"
      @call="setUrlCall()"
    >
      <div class="search-box pb-3">
        <input
          type="text"
          min="1"
          v-model="url"
          class="form-control"
          placeholder="Base URI"
          required
        />
      </div>
    </baseModal>
    <!--End URL Modal-->
    <!--Set HoldMax Modal-->
    <baseModal
      title="HoldMax"
      labelButton="SET HOLDMAX"
      :show="holdMaxShow"
      @close="[holdMaxShow = false, closeModal()]"
      @call="setHoldMax()"
    >
      <div class="search-box pb-3">
        <input
          type="number"
          min="1"
          v-model="limit"
          class="form-control"
          placeholder="Limit"
          required
        />
      </div>
    </baseModal>
    <!--End HoldMax Modal-->
    <!--Set Stages Modal-->
    <baseModal
      title="Stage"
      labelButton="Set Contract Stage"
      :show="stageShow"
      @close="[stageShow = false, closeModal()]"
      @call="setStage()"
    >
      <div class="search-box pb-3">
        <input
          type="number"
          min="1"
          v-model="stage"
          class="form-control"
          placeholder="Stage"
          required
        />
      </div>
    </baseModal>
    <!--End Stages Modal-->
  </Layout>
</template>

<script>
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";

import { ethers } from "ethers";

SwiperCore.use([]);

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import baseModal from "@/components/widgets/baseModal";
import loadingBackDrop from "@/components/widgets/loadingBackDrop";
import Swal from "sweetalert2";
import { utils } from "ethers";

import store from "@/state/store";
import {
  getProvider,
  validateAddress,
  validateNumber,
} from "../../lib/ethersConnect";
import { markRaw } from "vue";

export default {
  components: {
    Layout,
    PageHeader,
    baseModal,
    loadingBackDrop
  },
  created: async function () {
    // Gather configuration
    console.log(store.getters["config/settings"])
    try {
      store.dispatch("config/loadContracts");
      const contractPreview =
        store.getters["config/settings"].contracts.coltonPreview;
      const { address, abi } = contractPreview;
      this.address = address;
      this.abi = abi;
    } catch (err) {
      console.log(err);
    }
  },

  mounted: async function () {
    try {
      store.dispatch("web3/init");
      store.dispatch("web3/connect");
    } catch (err) {
      console.log(err);
    }
    const provider = getProvider();
    if (provider) {
      const signer = provider.getSigner();
      const instance = new ethers.Contract(this.address, this.abi, signer);
      this.instanceContract = markRaw(instance);
      this.signer = markRaw(signer);
      this.address = await signer.getAddress();
      this.isOpen = await this.instanceContract.open();
      this.isFreeNftEnabled = await this.instanceContract.airdropFreeToken();
      const pc = await this.instanceContract.getETHPrice();
      const balanceInEth = ethers.utils.formatEther(pc);

      const balance = await provider.getBalance(this.instanceContract.address);
      
      this.balance = ethers.utils.formatEther(balance);
      console.log("Balance ETH", this.balance);
      this.whitelisted = await this.instanceContract.whitelisted();
      this.price = balanceInEth;
    }
  },
  data() {
    return {
      title: "Colton Preview Dashboard",
      breadcrumbs: [],
      instanceContract: {},
      address: null,
      abi: null,
      signer: null,
      balance: "",

      //airDrop
      airDropShow: false,
      airdropAd: "",
      airDropToken: "",
      isOpen: true,
      isFreeNftEnabled: false,
      //End airDrop
      //Withdraw
      withdrawShow: false,
      withdrawAd: "",
      withdrawToken: "",
      //End Withdraw

      //Max Supply
      maxSupplyShow: false,
      maxSupply: 200,
      //End Withdraw

      //SetPrice
      priceShow: false,
      priceUSD: "",
      price: "",
      whitelisted: null,
      //End SetPrice

      //SetMinMax
      mintMaxShow: false,
      mintMax: "",
      //End SetMinMax

      //SetMinMax
      payoutAddressShow: false,
      payoutAddress: "",
      //End SetMinMax

      //SetHoldMax
      holdMaxShow: false,
      holdMax: "",
      limit: "",
      //End SetHoldMax

      //SetUrl
      urlShow: false,
      url: "",
      //End Url

      //Stages
      stageShow: false,
      stage: "",
      //End Stages

      //Loading
      loadingTx: false,
      //End Loading
    };
  },
  computed: {
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    preview() {
      const contractInfo = store.getters["config/contracts"].coltonPreview;
      if (!contractInfo) {
        return {};
      } else {
        return contractInfo;
      }
    },
  },
  methods: {
    rightcolumn() {
      if (
        document
          .querySelector(".layout-rightside-col")
          .classList.contains("d-none")
      ) {
        document
          .querySelector(".layout-rightside-col")
          .classList.remove("d-none");
      } else {
        document.querySelector(".layout-rightside-col").classList.add("d-none");
      }
    },

    closeModal() {
      this.loadingTx = false; 
    },

    async setFreeAirdrop() {
      var title = "";
      this.freeAirdrop = await this.instanceContract.airdropFreeToken();
      if (this.freeAirdrop) {
        title = "Do you want to enable free airdrops?";
      } else {
        title = "Do you want to disable free airdrops?";
      }

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          text: title,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.loadingTx = true;
              const tx = await this.instanceContract.setAirdropFreeToken(
                !this.airdropFreeToken
              );
              const transactionReceipt = await tx.wait();
              if (transactionReceipt.status !== 1) {
                this.loadingTx = false;
                this.showAlert("Error in transaction", "error");
                return;
              } else {
                this.loadingTx = false;
                this.airdropFreeToken = !this.airdropFreeToken;
                this.showAlert("Success", "success");
              }
            } catch (err) {
              this.loadingTx = false;
              const error =
                err?.reason ??
                err?.response?.data?.message ??
                err?.message ??
                err;
              this.showAlert(error, "error");
            }
          }
        });
    },

    async setWhiteListed() {
      var title = "";
      this.whitelisted = await this.instanceContract.whitelisted();
      if (this.whitelisted) {
        title = "Are you sure to public mint the contract?";
      } else {
        title = "Are you sure to Whitelisted the contract?";
      }

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          text: title,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.loadingTx = true;
              const tx = await this.instanceContract.setWhitelisted(
                !this.whitelisted
              );
              const transactionReceipt = await tx.wait();
              if (transactionReceipt.status !== 1) {
                this.loadingTx = false;
                this.showAlert("Error in transaction", "error");
                return;
              } else {
                this.whitelisted = !this.whitelisted;
                this.loadingTx = false;
                this.showAlert("Success", "success");
              }
            } catch (err) {
              this.loadingTx = false;
              const error =
                err?.reason ??
                err?.response?.data?.message ??
                err?.message ??
                err;
              this.showAlert(error, "error");
            }
          }
        });
    },

      async enableDisableFreeAirdrop() {
      var title = "";
      const freeAirdrop = await this.instanceContract.airdropFreeToken();
      if (freeAirdrop) {
        title = "Are you sure to Disable the Free Airdrop?";
      } else {
        title = "Are you sure to Enable the Free Airdrop?";
      }
      console.log("as")

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          text: title,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const open = await this.instanceContract.airdropFreeToken();
              this.loadingTx = true;
              const tx = await this.instanceContract.setAirdropFreeToken(!open);
              const transactionReceipt = await tx.wait();
              if (transactionReceipt.status !== 1) {
                this.loadingTx = false;
                this.showAlert("Error in transaction", "error");
                return;
              } else {
                this.isFreeNftEnabled = !open;
                this.loadingTx = false;
                this.showAlert("Success", "success");
              }
            } catch (err) {
              this.loadingTx = false;
              const error =
                err?.reason ??
                err?.response?.data?.message ??
                err?.message ??
                err;
              this.showAlert(error, "error");
            }
          }
        });
    },

    async openAndClose() {
      var title = "";
      const open = await this.instanceContract.open();
      if (open) {
        title = "Are you sure to close the contract?";
      } else {
        title = "Are you sure to open the contract?";
      }

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          text: title,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.loadingTx = true;
              const open = await this.instanceContract.open();
              const tx = await this.instanceContract.setOpen(!open);
              const transactionReceipt = await tx.wait();
              if (transactionReceipt.status !== 1) {
                this.loadingTx = false;
                this.showAlert("Error in transaction", "error");
                return;
              } else {
                this.loadingTx = false;
                this.isOpen = !open;
                this.showAlert("Success", "success");
              }
            } catch (err) {
              this.loadingTx = false;
              const error =
                err?.reason ??
                err?.response?.data?.message ??
                err?.message ??
                err;
              this.showAlert(error, "error");
            }
          }
        });
    },
    async openWithdraw() {
      this.withdrawShow = true;
    },
    async withdraw() {
      if (!this.withdrawToken) {
        this.showAlert("Invalid amount", "error");
        return;
      }
      //const amount = token.mul();
      const transactionPopulated =
        await this.instanceContract.populateTransaction.withdrawETH(
          utils.parseEther(this.withdrawToken.toString())
        );

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.withdrawShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.showAlert("Success", "success");
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },

    async openMintMaxModal() {
      this.mintMaxShow = true;
    },
    async setMintMax() {
      if (!validateNumber(this.mintMaxAmount)) {
        this.showAlert("Invalid amount", "error");
        return;
      }
      //const amount = token.mul();
      const transactionPopulated =
        await this.instanceContract.populateTransaction.setMintMax(
          this.mintMaxAmount
        );

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.mintMaxShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.loadingTx = false;
        this.showAlert("Success", "success");
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },

    async openPayoutAddressModal() {
      this.payoutAddressShow = true;
    },
    async setPayoutAddress() {
      console.log(this.payoutAddress);
      //const amount = token.mul();
      const transactionPopulated =
        await this.instanceContract.populateTransaction.setPayoutAddress(
          this.payoutAddress
        );

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.payoutAddressShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.showAlert("Success", "success");
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },

    async openHoldMax() {
      this.holdMaxShow = true;
    },
    async setHoldMax() {
      if (!validateNumber(this.limit)) {
        this.showAlert("Invalid limit", "error");
      }
      //const amount = utils.parseEther(this.holdMax);
      console.log("AMOUNT", this.limit);
      const transactionPopulated =
        await this.instanceContract.populateTransaction.setHoldMax(this.limit);

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.holdMaxShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.loadingTx = false;
        this.showAlert("Success", "success");
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },

    async openMaxSupplyModal() {
      this.maxSupplyShow = true;
    },
    async setMaxSupply() {
      if (!validateNumber(this.maxSupply)) {
        this.showAlert("Invalid amount", "error");
        return;
      }
      const transactionPopulated =
        await this.instanceContract.populateTransaction.setMaxSupply(
          this.maxSupply
        );

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.maxSupplyShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.loadingTx = false;
        this.showAlert("Success", "success");
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },
    showAlert(title, icon = "info") {
      Swal.fire({
        icon: icon,
        text: title,
      });
    },

    async openSetStageModal() {
      this.stageShow = true;
    },
    async setStage() {
      if (!validateNumber(this.stage)) {
        this.showAlert("Invalid stage", "error");
        return;
      }
      const transactionPopulated =
        await this.instanceContract.populateTransaction.setStage(this.stage);

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
        this.loadingTx = false;
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.stageShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.loadingTx = false;
        this.showAlert("Success", "success");
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },

    async openAirDropModal() {
      this.airDropShow = true;
    },

    async airDrop() {
      if (!validateAddress(this.airdropAd)) {
        this.showAlert("Invalid address", "error");
        return;
      } else if (!validateNumber(this.airDropToken)) {
        this.showAlert("Invalid amount", "error");
        return;
      }
      //const amount = token.mul();
      const transactionPopulated =
        await this.instanceContract.populateTransaction.airdrop(
          this.airdropAd,
          this.airDropToken
        );

      const txData = {
        ...transactionPopulated,
      };
      let tx;

      try {
        this.loadingTx = true;
        tx = await this.signer.sendTransaction(txData);
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
      try {
        this.airDropShow = false;
        this.loadingTx = true;
        await tx.wait();
        this.loadingTx = false;
        this.showAlert("Success", "success");
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
        return;
      }
    },

    calculatePrice() {
      const cal =
        (1000000000000000000 * this.priceUSD * 10 ** 8) / Number(this.price);
      return Number(cal).toPrecision(18) / 10 ** 18 / 10 ** 18;
    },

    async setPrice() {
      if (this.price === "") {
        const provider = getProvider();
        const balance = await provider.getBalance(this.address);
        const contractBalanceInEth = ethers.utils.formatEther(balance);
        this.price = contractBalanceInEth;
      }
      this.priceShow = true;
    },

    async callSetPrice() {
      if (!validateNumber(this.priceUSD)) {
        this.showAlert("Invalid price", "error");
        return;
      }
      try {
        this.loadingTx = true;
        const tx = await this.instanceContract.setUSDPrice(this.priceUSD);
        this.priceShow = false;
        const transactionReceipt = await tx.wait();
        if (transactionReceipt.status !== 1) {
          this.loadingTx = false;
          this.showAlert("Error in transaction", "error");
          return;
        } else {
          this.loadingTx = false;
          this.showAlert("Success", "success");
        }
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
      }
    },

    setUrl() {
      this.urlShow = true;
    },
    async setUrlCall() {
      if (this.url === "") {
        this.showAlert("Invalid base URI", "error");
        return;
      }
      try {
        this.loadingTx = true;
        const tx = await this.instanceContract.setBaseURI(this.url);
        this.urlShow = false;
        const transactionReceipt = await tx.wait();
        if (transactionReceipt.status !== 1) {
          this.loadingTx = false;
          this.showAlert("Error in transaction", "error");
          return;
        } else {
          this.loadingTx = false;
          this.showAlert("Success", "success");
        }
      } catch (err) {
        this.loadingTx = false;
        const error =
          err?.reason ?? err?.response?.data?.message ?? err?.message ?? err;
        this.showAlert(error, "error");
      }
    },
  },
};
</script>
