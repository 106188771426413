<template>
<div class="container p-5"> 
  <div id="exampleModal">
    <transition @enter="startTransitionModal" @after-enter="endTransitionModal" @before-leave="endTransitionModal" @after-leave="startTransitionModal">
      <div class="modal fade" v-show="show" ref="modal" @click.self="showModal = false">
        <div class="modal-dialog modal-dialog-centered" role="dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
              <button class="btn btn-primary" type="button" @click="this.$emit('close')"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <slot />
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" @click="this.$emit('close')">Close</button>
              <button @click="this.$emit('call')" class="btn btn-primary" type="button">{{ labelButton }}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
   <div class="modal-backdrop fade d-none" ref="backdrop"></div>
  </div>
</div>
</template>
<script>
  export default {
  components: {},
    created: async function() {},

  mounted: async function () { },
    props: {
      labelButton: String,
      title: String,
      show: Boolean,
    },
    data() {
      return {
        showModal: false,
      };
    },
    computed: {},
    methods: {
      startTransitionModal() {
      this.$refs.backdrop.classList.toggle("d-block");
      this.$refs.modal.classList.toggle("d-block");
    },
    endTransitionModal() {
      this.$refs.backdrop.classList.toggle("show");
      this.$refs.modal.classList.toggle("show");
    }
    }

  };
</script>
<style scoped>
.modal-content{
  border-color: #00000073};
</style>